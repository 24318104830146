import useSWRMutation from "swr/mutation";

interface RegisterProps {
	arg: {
		email: string;
		username: string;
		password: string;
	};
}

const register = async (_: string, { arg }: RegisterProps) => {
	const AUTH_URL = process.env.NEXT_PUBLIC_BACKEND_AUTH_URL || "";
	const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || "";
	const { email, username, password } = arg;
	const rawResponse = await fetch(`${AUTH_URL ?? BACKEND_URL}/register`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			email,
			username,
			password,
		}),
	});
	const response = await rawResponse.json();

	if (response.code === 401 || response.code === 404) {
		throw new Error(response.message);
	}

	return {
		code: response.code,
		message: response.message,
		token: response?.token,
		user: response?.user,
	};
};

export const useRegister = () => {
	return useSWRMutation("useRegister", (_, arg) => register(_, arg));
};
