/* eslint-disable eslint-comments/require-description */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice } from "@reduxjs/toolkit";
import type { Slice } from "@reduxjs/toolkit";

export const cartInitialState = {
	cart: null,
	paymentSession: null,
	payment: null,
};

export const cartSlice: Slice = createSlice({
	name: "cart",
	initialState: cartInitialState,
	reducers: {
		hydrateCart: (state, action) => {
			return action.payload;
		},
		setCart(state, action) {
			state.cart = action.payload.cart;
		},
		setPaymentSession(state, action) {
			state.paymentSession = action.payload.paymentSession;
		},
		setPayment(state, action) {
			state.payment = action.payload.payment;
		},
		clearCart(state) {
			state.cart = null;
		},
		clearPaymentSession(state) {
			state.paymentSession = null;
		},
		clearPayment(state) {
			state.payment = null;
		},
	},
});

export const {
	setCart,
	setPaymentSession,
	setPayment,
	clearCart,
	clearPaymentSession,
	clearPayment,
	hydrateCart,
} = cartSlice.actions;
