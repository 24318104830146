"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import { Checkin } from ".";
import { CheckinCard } from "./CheckinCard";
import { useEffect, useState } from "react";

export const CheckinCarouselClient = ({
	checkins,
}: {
	checkins: Checkin[];
}) => {
	const [width, setWidth] = useState(0);
	const [amount, setAmount] = useState(0);

	useEffect(() => {
		if (typeof window !== "undefined") {
			setWidth(window.innerWidth);
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, []);

	useEffect(() => {
		if (width < 768) setAmount(1);
		else setAmount(Math.floor((width - 128) / 280));
	}, [width]);

	return (
		<Swiper
			spaceBetween={50}
			slidesPerView={amount}
			modules={[Navigation, Pagination, Scrollbar, A11y]}
			navigation
		>
			{checkins?.length &&
				checkins?.map((checkin: Checkin) => (
					<SwiperSlide key={checkin.id} className="checkin-carousel__slide">
						<CheckinCard key={checkin.id} checkin={checkin} />
					</SwiperSlide>
				))}
		</Swiper>
	);
};
