"use client";
import { logout } from "redux-store/reducers";
import { store } from "redux-store/store";

export const useLogout = () => {
	const dispatch = store.dispatch;

	dispatch(logout({}));
	window.location.href = "/login";
};
