"use client";
import { store } from "redux-store/store";

export const useAuth = () => {
	const { user, credentials } = store.getState().auth;

	return {
		user,
		credentials,
	};
};
