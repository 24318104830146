/* eslint-disable no-constant-binary-expression */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
"use client";

import jwtDecode from "jwt-decode";
import { useRouter } from "next/navigation";

export const useCheckAuthAndRedirect = ({
	location,
	redirect,
	login,
}: {
	location: string;
	redirect?: string;
	login?: string;
}) => {
	const router = useRouter();

	if (typeof window === "undefined") return null;
	// if (localStorage.getItem("auth/user") === null) return null;

	const token: string | undefined = JSON.parse(
		localStorage.getItem("auth/user") || "{}"
	)?.credentials;

	if (token) {
		const decoded = jwtDecode(token);
		if (decoded) {
			if (location !== redirect && location !== "dashboard") {
				router.push(`/${redirect}` ?? "/dashboard");
				return true;
			}
		} else if (location === "dashboard" || location === redirect) {
			router.push(login ?? "/");
		} else {
			return false;
		}
	} else if (location === "dashboard" || location === redirect) {
		router.push(login ?? "/");
	}
};
