import useSWRMutation from "swr/mutation";

interface LoginProps {
	arg: {
		email: string;
		password: string;
	};
}

const login = async (_: string, { arg }: LoginProps) => {
	const AUTH_URL = process.env.NEXT_PUBLIC_BACKEND_AUTH_URL;
	const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;
	const { email, password } = arg;
	const rawResponse = await fetch(`${AUTH_URL ?? BACKEND_URL}/login`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			login: email,
			password,
		}),
	});
	const response = await rawResponse.json();

	if (response.code === 401 || response.code === 404) {
		throw new Error(response.message);
	}

	return {
		code: response.code,
		message: response.message,
		token: response?.token,
		user: response?.user,
	};
};

export const useLogin = () => {
	return useSWRMutation("useLogin", (_, arg) => login(_, arg));
};
